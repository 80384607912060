import React, { useMemo } from "react";
import { CellProps } from "./Cell";
import { BASE_COLUMN_TYPES, ColumnDataMap, REPORT_TOOLTIP_TARGET_ID } from "../config/types";
import Avatar from "../../../helpers/Avatar";
import { useSelector } from "react-redux";
import { getUserImageByFileId } from "../../../../selectors";
import { getImageSrc } from "../../../../utils";
import { useTooltipAttributes } from "../../../../hooks";

type TColumn = typeof BASE_COLUMN_TYPES.Avatar;
const AvatarCell = ({
  column,
  row,
  className,
  onClick,
  style = {},
}: CellProps<TColumn>) => {
  const { accessorKey, avatarOptions, dataTransform } = column;
  const { full_name, avatar_file_id, tooltipText } = useMemo<ColumnDataMap<TColumn>>(() => {
    if (!dataTransform) {
      throw new Error("AvatarCell requires dataTransform to be provided");
    }

    return { ...dataTransform(row.original[accessorKey]) };
  }, [accessorKey, dataTransform, row.original]);

  const userImage = useSelector((s) => getUserImageByFileId(s, avatar_file_id));

  const tooltipAttributes = useTooltipAttributes(tooltipText ?? full_name, REPORT_TOOLTIP_TARGET_ID);

  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <Avatar
        {...tooltipAttributes}
        {...(avatar_file_id && userImage && { src: getImageSrc(userImage) })}
        name={full_name}
        style={{
          width: 40,
          height: 40,
          paddingRight: 0,
          color: "#fff",
        }}
      />
      {avatarOptions?.withFullName && <div>{full_name}</div>}
    </div>
  );
};

export default AvatarCell;
